@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.nav-mobile {
  text-align: center;
}

.nav-mobile__caret1 {
  @include caret;
  line-height: 0.1rem;
}

.nav-mobile__caret2 {
  @include caret;
  line-height: 1.9rem;
}

.nav-mobile__caret3 {
  @include caret;
  line-height: 1.9rem;
}

.nav-mobile__caret4 {
  @include caret;
  line-height: 3.9rem;
  margin-top: -15px;
  padding-bottom: 1.5rem;
}
