$primary1: #a7dfff;
$primary1-background-lighter: #ddf2ff;
$primary-font1: white;
$primary-font2: #70ffd4;
$primary-font3: black;
$primary-hover: #fffdc3;
$primary-hover2: #ffd1e7;
$primary-hover2-light: #ffdded;

$primary-background2: white;
