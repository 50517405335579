@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.desktop-text-box-new {
  &__main {
    @include desktop-text-box-main;
  }

  @keyframes about-main {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__section {
    @include desktop-text-box-section;
  }

  &__header {
    @include desktop-text-box-header;
  }

  &__subheader {
    @include desktop-text-box-subheader;
  }

  &__list {
    @include desktop-text-box-subheader-list;
  }

  &__list-item {
    margin-bottom: 0.5rem;
  }

  &__text-link {
    @include desktop-text-box-subheader-text-link;
  }

  &__text-link:hover {
    color: $primary-hover2;
  }
}
