@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.desktop-text-box-new {
  &__main {
    @include desktop-text-box-main;
  }

  @keyframes about-main {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__section {
    @include desktop-text-box-section;
  }

  &__header {
    @include desktop-text-box-header;
  }

  &__subheader {
    @include desktop-text-box-subheader;
  }

  &__list {
    @include desktop-text-box-subheader-list;
  }

  &__list-item {
    margin-bottom: 0.5rem;
  }

  &__text-link {
    @include desktop-text-box-subheader-text-link;
  }

  &__text-link:hover {
    color: $primary-hover2;
  }

  &__screenshot-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    border: solid 3px black;
    width: 40%;
    margin: 1rem;

    &--wide {
      width: 93%;
    }
  }
}
