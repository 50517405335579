@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.navigation {
  display: flex;
  flex-direction: column;
  padding-right: 2.5rem;
  position: fixed;
  bottom: 5px;
  right: 5px;

  @include tablet {
    position: fixed;
    right: 0rem;
    padding-right: 1.3rem;
    margin-bottom: 0.2rem;
  }

  @include desktop {
    padding-right: 2.5rem;
    margin-bottom: 0rem;
  }

  &__about {
    font-size: 4.95rem;
    font-weight: 900;

    &--active {
      color: $primary-hover;
    }

    @include tablet {
      font-size: 3.55rem;
    }

    @include desktop {
      font-size: 4.95rem;
    }
  }

  &__about:hover {
    @include hover;
  }

  &__caret1 {
    @include caret;
    line-height: 1.2rem;

    @include tablet {
      font-size: 2rem;
      margin-top: -0.1rem;
    }

    @include desktop {
      @include caret;
      margin-top: 0rem;
    }
  }

  &__projects {
    font-size: 3.4rem;
    line-height: 3rem;
    font-weight: 900;

    &--active {
      color: $primary-hover;
    }

    @include tablet {
      font-size: 2.45rem;
      line-height: 2.3rem;
      margin-top: -0.1rem;
    }

    @include desktop {
      font-size: 3.4rem;
      line-height: 3rem;
      margin-top: 0rem;
    }
  }

  &__projects:hover {
    @include hover;
  }

  &__caret2 {
    @include caret;
    line-height: 3rem;

    @include tablet {
      font-size: 2rem;
      line-height: 2.1rem;
    }

    @include desktop {
      @include caret;
      margin-top: 0.45rem;
    }
  }

  &__education {
    font-size: 3rem;
    line-height: 0.9rem;
    font-weight: 900;

    &--active {
      color: $primary-hover;
    }

    @include tablet {
      font-size: 2.16rem;
      line-height: 0rem;
      margin-top: 0.4rem;
    }

    @include desktop {
      font-size: 3rem;
      line-height: 0.9rem;
      margin-top: 0.55rem;
    }
  }

  &__education:hover {
    @include hover;
  }

  &__caret3 {
    @include caret;
    line-height: 4.6rem;

    @include tablet {
      font-size: 2rem;
      margin-top: 0.8rem;
      line-height: normal;
    }

    @include desktop {
      @include caret;
      line-height: 4.6rem;
      margin-top: 0rem;
    }
  }

  &__experience {
    font-size: 2.8rem;
    line-height: 0rem;
    font-weight: 900;

    &--active {
      color: $primary-hover;
    }

    @include tablet {
      font-size: 2.05rem;
      line-height: 0rem;
      margin-top: 0.4rem;
    }

    @include desktop {
      font-size: 2.8rem;
      line-height: 0rem;
      margin-top: -0.2rem;
    }
  }

  &__experience:hover {
    @include hover;
  }

  &__caret4 {
    @include caret;
    line-height: 5.6rem;

    @include tablet {
      font-size: 2rem;
      line-height: normal;
      margin-top: 0.9rem;
    }

    @include desktop {
      @include caret;
      line-height: 5.6rem;
      margin-top: 0rem;
    }
  }
}
