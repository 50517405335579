@use "./styles/partials/typography";
@use "./styles/partials/resets";
@use "./styles/partials/variables" as *;

.app {
  color: $primary-font1;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 900; // why doesn't this work?
  background-color: $primary1;
}
