@use "../../styles/partials/variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: "Fira Code", Courier, monospace;
}

h1,
h2,
h4,
h4,
p,
ul {
  margin: 0;
}

a {
  text-decoration: none;
  color: $primary-font1;
}

/* width */
::-webkit-scrollbar {
  width: 1.2rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary1-background-lighter;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-hover2-light;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-hover2;
}

.Collapsible__trigger:hover {
  color: $primary-hover2-light;
  cursor: pointer;
}
