@use "../../styles/partials/variables" as *;

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop-large {
  @media screen and (min-width: 1460px) {
    @content;
  }
}

@mixin caret {
  font-size: 2.74rem;
  color: $primary-font2;
}

@mixin hover {
  color: $primary-hover;
  cursor: pointer;
}

@mixin mobile-textbox {
  background-color: $primary-font1;
  font-family: "Fira Code", Courier, monospace;
  font-size: 1rem;
  color: $primary-font3;
  margin: 0rem 1rem 1rem 1rem;
  padding: 1rem;
  text-align: left;
  border-radius: 10px;
  font-weight: 500;
}

@mixin mobile-text {
  &__header {
    font-size: 1rem;
    margin-bottom: 0rem;
    margin-top: 0rem;

    &--extra {
      margin-bottom: 1rem;
    }
  }

  &__subheader {
    font-size: 0.8rem;

    &--bottom {
      margin-bottom: 1rem;
    }
  }

  &__list {
    margin-bottom: 1rem;
    text-align: left;
  }

  &__list-item {
    margin-bottom: 0.5rem;
    margin-left: -1rem;
  }

  &__text {
    text-align: left;
    padding-bottom: 1rem;
    color: $primary-font3;
    font-size: 1rem;
  }

  &__text-link {
    padding-bottom: 1rem;
    color: $primary-font3;
    cursor: pointer;
    width: max-content;
    text-decoration: underline;
  }

  &__text-link:hover {
    color: $primary-hover2;
  }
}

@mixin desktop-text-box-main {
  @include tablet {
    margin-left: 1.5rem;
    // margin-top: 20.5rem;
    // max-height: 20rem;
    height: 70%;
    position: fixed;
    bottom: 1.5rem;
    width: 65%;
    background-color: $primary1-background-lighter;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    animation-name: about-main;
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
    font-weight: 500;
  }

  @include desktop {
    margin-left: 2.5rem;
    max-height: 25rem;
    width: 60%;
    bottom: 2.5rem;
  }

  @include desktop-large {
    max-height: 38rem;
    width: 68%;
  }
}

@mixin desktop-text-box-section {
  @include tablet() {
    margin: 1.3rem 0rem 1.1rem 1.2rem;
    padding: 1rem;
    background-color: $primary-background2;
    color: $primary-font3;
    font-family: "Fira Code", Courier, monospace;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include desktop {
    margin: 2.1rem 1rem 2rem 2rem;
  }
}

@mixin desktop-text-box-header {
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0rem;

  @include desktop {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  &--nospaceafter {
    margin-bottom: -2px;
  }
}

@mixin desktop-text-box-subheader {
  font-size: 0.8rem;

  @include desktop {
    margin-left: 1rem;
  }

  &--bottom {
    margin-bottom: 1rem;
  }
}

@mixin desktop-text-box-subheader-list {
  margin-bottom: 1rem;
  text-align: left;
  padding-left: 1.5rem;

  @include desktop {
    margin-left: 1rem;
  }
}

@mixin desktop-text-box-subheader-text-link {
  padding-bottom: 1rem;
  color: $primary-font3;
  cursor: pointer;
  width: max-content;
  text-decoration: underline;
}
