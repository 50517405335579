@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.projects-mobile {
  color: $primary-hover;
  font-size: 3.4rem;
  margin-top: 0.2rem;

  &__textbox {
    @include mobile-textbox;
    text-align: center;
  }

  @include mobile-text;

  &__text-header-h3 {
    margin-bottom: 1.5rem;
    margin-top: 0rem;
    text-align: left;
  }

  &__text-header {
    text-align: left;
    font-family: "Fira Code", Courier, monospace;
    margin-top: 0rem;
    margin-bottom: 0.8rem;
  }

  &__list {
    margin-bottom: 1.5rem;
  }

  &__link {
    // display: block;
    color: $primary-font3;
    cursor: pointer;
    width: max-content;
    text-decoration: underline;
  }

  &__screenshot-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    border: solid 3px black;
    width: 40%;
    margin: 1rem;

    &--wide {
      width: 93%;
    }
  }
}
