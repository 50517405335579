@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.desktop-text-box-about {
  @include tablet {
    &__main {
      margin-left: 1.5rem;
      margin-top: 2.5rem;
      max-height: 20rem;
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      position: fixed;
      bottom: 1.5rem;
      width: 65%;
      background-color: $primary-background2;
      color: $primary-font3;
      font-family: "Fira Code", Courier, monospace;
      font-weight: 500;
      border-radius: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      animation-name: about-main;
      animation-duration: 0.3s;
      animation-delay: 0.2s;
      animation-fill-mode: both;
    }
  }

  @include desktop {
    &__main {
      padding: 2.5rem 2.5rem 1.5rem 2.5rem;
      margin-left: 2.5rem;
      max-height: 25rem;
      width: 60%;
      bottom: 2.5rem;
    }
  }

  @include desktop-large {
    &__main {
      max-height: 35rem;
      width: 68%;
    }
  }

  @keyframes about-main {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__text {
    padding-bottom: 1rem;
    color: $primary-font3;

    &--icon {
      display: inline;
    }
  }

  &__text-link {
    @include tablet {
      padding-bottom: 1rem;
      color: $primary-font3;
      cursor: pointer;
      width: max-content;
      text-decoration: underline;
    }

    @include desktop {
      text-decoration: none;
    }
  }

  &__text-link:hover {
    color: $primary-hover2;
  }

  &__h3 {
    font-size: 1rem;
  }

  &__ul {
    margin-bottom: 1rem;
  }

  &__li {
    margin-bottom: 0.5rem;
  }
}
